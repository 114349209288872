import React from 'react';
import { Alert, AppBar, Avatar, CssBaseline, Divider, IconButton, LinearProgress, ListItemIcon, Menu, MenuItem, Snackbar, Toolbar, Typography } from '@mui/material'
import CameraIcon from '@mui/icons-material/PhotoCamera';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { makeStyles } from '@mui/styles';

import { PhotoList } from './components/PhotoList';
import { SignIn } from './components/SignIn';
import { UploadContent } from './components/UploadContent';
import { isRunningLocally } from './util/helpers';

import { Amplify, API, Auth, Hub, Storage, graphqlOperation } from 'aws-amplify';
import * as queries from './util/graphql/queries';
import * as subscriptions from './util/graphql/subscriptions';

//const axios = require('axios')

// TODO Look into improving build speed: https://jwchang0206.medium.com/make-create-react-app-faster-with-rust-6c75ffa8fdfd

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'right'
  },
}));

Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn':
      console.log('user signed in');
      break;
    case 'signUp':
      console.log('user signed up');
      break;
    case 'signOut':
      console.log('user signed out');
      break;
    case 'signIn_failure':
      console.error('user sign in failed');
      break;
    case 'tokenRefresh':
      console.log('token refresh succeeded');
      break;
    case 'tokenRefresh_failure':
      console.error('token refresh failed');
      break;
    case 'configured':
      console.log('the Auth module is configured');
      break;
    default:
      console.log('default auth event:', data)
  }
})

Hub.listen('api', (data) => {
  console.log('api event:', data)
})

export default function Album() {
  const classes = useStyles();
  const runningLocally = isRunningLocally();

  const [initialized, setInitialized] = React.useState(false)
  const [name, setName] = React.useState('')
  const [photoMetadata, setPhotoMetadata] = React.useState(null)
  const [authenticated, setAuthenticated] = React.useState(null)

  const [error, setError] = React.useState('')
  const [info, setInfo] = React.useState('')
  const [success, setSuccess] = React.useState('')
  const [showAlert, setShowAlert] = React.useState(false)
  const [signInConfig, setSignInConfig] = React.useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl);

  Hub.listen('auth', async (data) => {
    if (data.payload.event === 'signIn') {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setName(`${user.attributes.given_name} ${user.attributes.family_name}`)
        setAuthenticated(true)
      } catch (error) {
        console.error('error handling sign-in event:', error)
      }
    }
  })

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async (e) => {
    try {
      await Auth.signOut()
      handleClose()
      window.location.reload()
    } catch (error) {
      console.error('error signing out: ', error)
    }
  };

  const clearAlert = (e, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowAlert(false)
    setTimeout(() => {
      setError('')
      setInfo('')
      setSuccess('')
    }, 1000)
  }

  const getSignInConfig = React.useCallback(async () => {
    if (signInConfig !== null) {
      return signInConfig
    } else {
      let splitDomain = window.location.hostname.split(".")
      splitDomain[0] = "signin"

      const signInConfigUrl = window.location.protocol + '//' + splitDomain.join('.') + '/config.json'

      try {
        const res = await fetch(signInConfigUrl)
        const data = res.json()
        setSignInConfig(data)
        return data
      } catch (err) {
        console.error('error retrieving sign-in config:', err)
        throw err
      }
    }
  }, [signInConfig])

  const initializeContent = React.useCallback(async () => {
    let initialContent;
    try {
      initialContent = await API.graphql(graphqlOperation(queries.initialize));
    } catch (error) {
      console.error('error initializing content:', error)
      return
    }

    for (let i in initialContent.data.getSignedCookies) {
      document.cookie = initialContent.data.getSignedCookies[i]
    }

    Storage.configure({
      AWSS3: initialContent.data.getPhotoDropBucket
    })

    let photos = initialContent.data.listPhotos.items.map(photo => {
      photo.photo_timestamp = new Date(parseInt(photo.photo_timestamp) * 1000)
      return photo
    })

    setPhotoMetadata(photos)

    API.graphql(graphqlOperation(subscriptions.photoUploaded)).subscribe({
      next: ({ provider, value }) => {
        console.log({ provider, value })

        let newEvent = {
          data: {etag: value.data.photoUploaded.etag}
        }

        if (value.data.photoUploaded.s3_key_prefix === null) {
          newEvent.event = 'photoRejected'
          newEvent.message = value.data.photoUploaded.reason
        } else {
          newEvent.event = 'photoAdded'
          newEvent.message = ''
        }

        Hub.dispatch(
          value.data.photoUploaded.etag + '_PhotoUploaded',
          newEvent
        )

        setPhotoMetadata((prevPhotos) => {
          // Convert timestamp to actual date
          let photo = value.data.photoUploaded
          photo.photo_timestamp = new Date(parseInt(photo.photo_timestamp) * 1000)

          prevPhotos.push(photo)
          return Array.from(prevPhotos)
        })
      },
      error: (error) => {
        console.warn('photoUploaded error:', error)
        setError('Error subscribing to updates: ' + error)
        // TODO Handle re-subscribe
      }
    })

    console.log('data initialized')
  }, [])

  const checkSession = React.useCallback(async () => {
    console.log('Checking auth session...')
    try {
      // TODO Remove logging later
      const session = await Auth.currentSession();
      console.log('current session:', session);
      const user = await Auth.currentAuthenticatedUser();
      console.log('Already logged in:', user);
      setName(`${user.attributes.given_name} ${user.attributes.family_name}`)
      setAuthenticated(true)
    } catch (error) {
      console.log('Need to authenticate:', error);
      setAuthenticated(false)
    }
  }, [])

  const configureAmplify = React.useCallback(async () => {
    let config;
    try {
      config = await getSignInConfig()
      console.log('Got sign-in config')
    } catch (error) {
      console.error('unable to retrieve sign-in config:', error)
      throw error
    }

    Amplify.configure({
      'aws_appsync_graphqlEndpoint': 'https://api.' + window.location.hostname + '/graphql',
      'aws_appsync_region': config.region,
      'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
      Auth: {
        identityPoolId: config.cognito_identity_pool_id,
        region: config.region,
        userPoolId: config.cognito_user_pool_id,
        userPoolWebClientId: config.cognito_user_pool_client_id,
        mandatorySignIn: true,
        authenticationFlowType: 'USER_SRP_AUTH',
      }
    })

    await checkSession()
  }, [checkSession, getSignInConfig])

  React.useEffect(() => {
    if (!runningLocally) {
      if (!initialized) {
        configureAmplify()

        return setInitialized(true)
      }

      if (authenticated && !photoMetadata) {
        initializeContent()
      }

    } else {
      console.log('running locally')
      setInitialized(true)
    }

    if (success || error || info) {
      setShowAlert(true)
    } else {
      setShowAlert(false)
    }
  }, [configureAmplify, initializeContent, authenticated, error, info, initialized, photoMetadata, runningLocally, success])

  // TODO
  // Ways to sort photos:
  // - most recent uploads
  // - chronologically

  // Ways to search photos:
  // - by date range
  // - by uploader
  // - by who is in the photo

  return initialized ? (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="sticky">
        <Toolbar>
          <CameraIcon className={classes.icon} />
          {name.length > 0 && (
            <React.Fragment>
              <Typography color="inherit" noWrap className={classes.title}>
                {`Hello ${name.split(" ")[0]}`}
              </Typography>
              <IconButton size="large" onClick={handleClick}>
                <Avatar className={classes.avatar}>
                  {name.charAt(0).toUpperCase()}
                </Avatar>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                  <Avatar /> Profile
                </MenuItem>
                <MenuItem>
                  <Avatar /> My account
                </MenuItem>
                <Divider />
                <MenuItem>
                  <ListItemIcon>
                    <PersonAdd fontSize="small" />
                  </ListItemIcon>
                  Add another account
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>

      <main>
        {authenticated === false ? (
          <SignIn
            setAuthenticated={setAuthenticated}
            setError={setError}
          />
        ) : (
          <PhotoList
            photoMetadata={photoMetadata}
            runningLocally={runningLocally}
          />
        )}
      </main>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={showAlert}
        autoHideDuration={success ? 10000 : null}
        onClose={clearAlert}
      >
        <Alert elevation={6} variant="filled" onClose={clearAlert} severity={success ? "success" : (error ? "error" : "info")}>
          {success || error || info}
        </Alert>
      </Snackbar>

      <UploadContent
        authenticated={authenticated}
        clearAlert={clearAlert}
        photoMetadata={photoMetadata}
        setError={setError}
        setInfo={setInfo}
        setSuccess={setSuccess}
      />
    </React.Fragment>
  ) : (
    <LinearProgress />
  );
}
