/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGraphqlEndpoint = /* GraphQL */ `
  query GetGraphqlEndpoint {
    getGraphqlEndpoint
  }
`;
export const getPhotoDropBucket = /* GraphQL */ `
  query GetPhotoDropBucket {
    getPhotoDropBucket {
      bucket
      region
    }
  }
`;
export const getSignedCookies = /* GraphQL */ `
  query GetSignedCookies {
    getSignedCookies
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos($limit: Int, $nextToken: String) {
    listPhotos(limit: $limit, nextToken: $nextToken) {
      items {
        archived
        description
        etag
        photo_timestamp
        s3_key_prefix
        uploaded
        uploaded_by
      }
      nextToken
    }
  }
`;
export const initialize = /* GraphQL */ `
  query Initialize($limit: Int, $nextToken: String) {
    getGraphqlEndpoint
    getPhotoDropBucket {
      bucket
      region
    }
    getSignedCookies
    listPhotos(limit: $limit, nextToken: $nextToken) {
      items {
        archived
        description
        etag
        photo_timestamp
        s3_key_prefix
        uploaded
        uploaded_by
      }
      nextToken
    }
  }
`;
