import React from 'react'
import { makeStyles } from '@mui/styles';

// Inspiration taken from https://codepen.io/saransh/pen/BKJun
const starfieldHeight = Math.max(window.innerHeight, window.innerWidth) * 2

function starfieldStyle(starSize, duration, boxShadows) {
  return {
    width: starSize,
    height: starSize,
    background: 'transparent',
    boxShadow: boxShadows,
    animationName: '$animStar',
    animationDuration: duration,
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite',
    '&:after': {
      content: ' ',
      position: 'absolute',
      top: `${starfieldHeight}px`,
      width: starSize,
      height: starSize,
      background: 'transparent',
      boxShadow: boxShadows
    }
  }
}

function randomPixel() {
  return Math.floor(Math.random() * starfieldHeight) + 'px'
}

function multipleBoxShadow(n) {
  let value = []
  for (let i = 0; i < n; i++) {
    value.push([randomPixel(), randomPixel(), '#FFF'].join(' '))
  }

  // Find all pixels that start in the original viewport, create an offset copy and add to the array
  // (This gives the illusion of an infinite starfield)
  let offsetStartingPixels = value.filter((pixel) => {
    let yOffset = parseInt(pixel.split(' ')[1].replace('px', ''))
    return yOffset < window.innerHeight
  }).map((pixel) => {
    let splitPixel = pixel.split(' ')
    let yOffset = parseInt(splitPixel[1].replace('px', ''))
    splitPixel[1] = `${yOffset + starfieldHeight}px`
    return splitPixel.join(' ')
  })

  return value.concat(offsetStartingPixels).join(' , ')
}

const useStyles = makeStyles({
  '@keyframes animStar': {
    from: {
      transform: 'translateY(0px)'
    },
    to: {
      transform: `translateY(-${starfieldHeight}px)`
    }
  },
  stars: starfieldStyle('1px', '150s', multipleBoxShadow(700)),
  stars2: starfieldStyle('2px', '100s', multipleBoxShadow(200)),
  stars3: starfieldStyle('3px', '40s', multipleBoxShadow(100)),
})

export function Starfield() {
  const classes = useStyles()

  return (
    <div>
      <div className={classes.stars}></div>
      <div className={classes.stars2}></div>
      <div className={classes.stars3}></div>
    </div>
  );
}
