export function getAccessToken() {
  return sessionStorage.getItem('access_token') || localStorage.getItem('access_token')
}

export function getIdToken() {
  return sessionStorage.getItem('id_token') || localStorage.getItem('id_token')
}

export function isRunningLocally() {
  return window.location.hostname === 'localhost'
}

export function redirectToSignIn() {
  // Clear all tokens
  localStorage.clear()
  sessionStorage.clear()

  // Redirect to the sign-in page (signin.<my domain>)
  let splitDomain = window.location.hostname.split(".")
  splitDomain[0] = "signin"

  window.location.href = window.location.protocol + '//' + splitDomain.join('.')
}

export function emailValid(eml) {
  return /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[A-Za-z]+$/.test(eml)
}
