import React from 'react';
import { Grid, Link, List, ListItem, ListSubheader, Skeleton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Photo } from './Photo';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [1, 2, 3, 4, 5, 6];
const sections = [1, 2, 3];

export function PhotoList(props) {
  const classes = useStyles();

  const [photosByMonth, setPhotosByMonth] = React.useState({});

  React.useEffect(() => {
    let newPhotosByMonth = {}
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    if (props.photoMetadata) {
      console.log('Updating photo list:', props.photoMetadata)
      for (let photo of props.photoMetadata) {
        let monthYearString = [months[photo.photo_timestamp.getMonth()], photo.photo_timestamp.getFullYear()].join(' ')
        if (newPhotosByMonth[monthYearString] === undefined) {
          newPhotosByMonth[monthYearString] = []
        }

        newPhotosByMonth[monthYearString].push(photo)
      }

      for (let monthYearString in newPhotosByMonth) {
        newPhotosByMonth[monthYearString].sort((a, b) => {
          return b.photo_timestamp.getTime() - a.photo_timestamp.getTime()
        })
      }
    }

    setPhotosByMonth(newPhotosByMonth)
  }, [props.photoMetadata])

  // TODO
  // Ways to sort photos:
  // - most recent uploads
  // - chronologically

  // Ways to search photos:
  // - by date range
  // - by uploader
  // - by who is in the photo

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '100%',
        '& ul': { padding: 0 },
      }}
      subheader={<li />}
    >
      {Object.keys(photosByMonth).length > 0 ? (
        Object.keys(photosByMonth).map((monthYear, index) => (
          <li key={index}>
            <ul>
              <ListSubheader>{monthYear}</ListSubheader>
              <ListItem>
                <Grid container spacing={4}>
                  {photosByMonth[monthYear].map((photo, photoIndex) => (
                    <Grid item key={photoIndex} xs={12} sm={6} md={4} xl={3}>
                      <Photo
                        image={`${window.location.origin}/${photo.s3_key_prefix}preview.webp`}
                        title=""
                        heading=""
                        description={photo.description || ""}
                      />
                    </Grid>
                  ))}
                </Grid>
              </ListItem>
            </ul>
          </li>
        ))
      ) : (
        props.runningLocally ? (
          sections.map((sectionId) => (
            <li key={`section-${sectionId}`}>
              <ul>
                <ListSubheader>{`Section ${sectionId}`}</ListSubheader>
                <ListItem>
                <Grid container spacing={4}>
                  {cards.map((card) => (
                    <Grid item key={card} xs={12} sm={6} md={4} xl={3}>
                      {card % 2 === 0 ? (
                        <Photo
                          image="https://source.unsplash.com/random"
                          title="Image title"
                          heading="Heading"
                          description="This is a media card. You can use this section to describe the content."
                        />
                      ) : (
                        <Photo />
                      )}
                    </Grid>
                  ))}
                </Grid>
                </ListItem>
              </ul>
            </li>
          ))
        ) : (
          <Skeleton animation="wave" variant="rect" height={200} />
        )
      )}

      <li>
        <ul>
          <ListItem>
            <footer className={classes.footer}>
              <Typography variant="h6" align="center" gutterBottom>
                Footer
              </Typography>
              <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                Something here to give the footer a purpose!
              </Typography>
              <Copyright />
            </footer>
          </ListItem>
        </ul>
      </li>
    </List>
  );
}
