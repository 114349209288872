import React from 'react';
import { Avatar, Box, Button, CircularProgress, Container, CssBaseline,
  Grid, Link, Paper, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from '@mui/styles';
import { emailValid } from '../util/helpers';

import { Starfield } from './Starfield';

import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  image: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)',
    overflow: 'hidden',
    zIndex: -1,
  },
  paper: {
    margin: theme.spacing(8, 4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    color: theme.palette.getContrastText(theme.palette.primary.main)
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href='#'>
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function SignIn(props) {
  const classes = useStyles();

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('')

  const [processing, setProcessing] = React.useState(false)
  const [showCreateAccount, /*setShowCreateAccount*/] = React.useState(false)
  //const [showForgotPassword, setShowForgotPassword] = React.useState(false)

  const handleSignin = async (e) => {
    e.preventDefault()
    setProcessing(true)

    try {
      const user = await Auth.signIn(email, password)
      console.log('Successful sign in: ', user)
      props.setAuthenticated(true)
    } catch (error) {
      props.setError(error)
    }

    setProcessing(false)
  }

  const allInputsValid = () => {
    return (emailValid(email) && password.length > 0)
  }

  const handleForgotPasswordClick = () => {}
  const handleCreateAccountClick = () => {}

  return (
    <React.Fragment>
      <CssBaseline />
      <Container disableGutters maxWidth={false} className={classes.image}>
        <Starfield />
      </Container>

      <Container maxWidth={'md'}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} onSubmit={handleSignin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              autoComplete="email"
              autoFocus
              onChange={(e) => {setEmail(e.target.value)}}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => {setPassword(e.target.value)}}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={showCreateAccount || !allInputsValid()}
              onClick={handleSignin}
            >
              { processing && !showCreateAccount ? <CircularProgress className={classes.progress} /> : "Sign In" }
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={handleForgotPasswordClick}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2" onClick={handleCreateAccountClick}>
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </Paper>
      </Container>
    </React.Fragment>
  );
}
