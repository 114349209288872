import React from 'react';
import { Card, CardActionArea, CardActions, CardContent, CardMedia, IconButton, Skeleton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

export function Photo(props) {
  const classes = useStyles();

  return (
    props.image ? (
      <Card className={classes.card}>
        <CardActionArea>
          <CardMedia
            className={classes.cardMedia}
            image={props.image}
            title={props.title}
          />
          {(props.heading.length > 0 || props.description.length > 0) &&
            <CardContent className={classes.cardContent}>
              {props.heading.length > 0 &&
                <Typography gutterBottom variant="h5" component="h2">{props.heading}</Typography>
              }
              {props.description.length > 0 &&
                <Typography>{props.description}</Typography>
              }
            </CardContent>
          }
        </CardActionArea>
        <CardActions>
          <IconButton aria-label="edit">
            <EditIcon />
          </IconButton>
        </CardActions>
      </Card>
    ) : (
      <Card className={classes.card}>
        <Skeleton sx={{ height: 230 }} animation="wave" variant="rectangular" />
        <CardContent>
          <Typography variant="h5" gutterBottom><Skeleton animation="wave" /></Typography>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </CardContent>
      </Card>
    )
  )
}
