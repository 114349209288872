import React from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle,
  Fab, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { API, Auth, Hub, Storage, graphqlOperation } from 'aws-amplify';
import * as mutations from '../util/graphql/mutations';

const CryptoJS = require('crypto-js')

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 100
  },
  fileName: {
    flexGrow: 1,
  },
  input: {
    display: 'none'
  },
  previewImage: {
    height: 'auto',
    maxHeight: '75vh',
    maxWidth: '100%'
  },
}))

export function UploadContent(props) {
  const classes = useStyles()

  // TODO May need to track processing by all files being uploaded
  const [processing, setProcessing] = React.useState(false)
  const [description, setDescription] = React.useState('') // TODO Description needs to be tracked at the file level
  const [filesAlreadyUploaded, setFilesAlreadyUploaded] = React.useState([])
  const [files, setFiles] = React.useState([])
  const [showUploadContent, setShowUploadContent] = React.useState(false)

  const handleClose = React.useCallback(() => {
    if (filesAlreadyUploaded.length > 0) {
      setFilesAlreadyUploaded([])
    } else {
      if (files.length > 0) {
        setFiles([])
      }

      setShowUploadContent(false)
    }
  }, [filesAlreadyUploaded.length, files.length])

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleUploadFiles = async (e) => {
    if (e) {
      e.preventDefault()
    }

    props.setInfo('Uploading photo(s)...')

    for (let i = 0; i < files.length; i++) {
      const file = files[i].file
      const md5 = files[i].md5

      let tags = new URLSearchParams()
      const currentUser = await Auth.currentAuthenticatedUser()
      tags.append('uploaded_by', currentUser.username)

      try {
        const result = await Storage.put(file.name, file, {
          progressCallback(progress) {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            updateFileProgress(i, Math.round(progress.loaded / progress.total * 100))
          },
          tagging: tags.toString()
        })

        console.log('Image successfully uploaded:', result)
      } catch (error) {
        console.error(error)
        props.setError(`Error uploading image: ${error}`)
        setFileStatus(i, 'failure')
        continue
      }

      Hub.listen(
        md5.toString() + '_PhotoUploaded',
        async (data) => {
          console.log('Received message:', data)
          if (data.payload.event === 'photoAdded') {
            props.setSuccess('Image successfully uploaded')
            setFileStatus(i, 'success')
            if (description.length > 0) {
              try {
                const descriptionUpdate = await API.graphql(graphqlOperation(mutations.addPhotoDescription, {
                  etag: md5.toString(),
                  description: description,
                }));
                console.log('Description updated:', descriptionUpdate)
              } catch (error) {
                console.error('Error updating description:', error)
              }
            }
          } else {
            props.setError('Error processing image: ' + data.payload.message)
          }
        }
      )
    }

    setProcessing(true)
    props.clearAlert()
  }

  const setFileStatus = (index, status) => {
    setFiles((prevFiles) => {
      prevFiles[index].status = status
      return Array.from(prevFiles)
    })
  }

  const updateFileProgress = (index, progress) => {
    setFiles((prevFiles) => {
      prevFiles[index].progress = progress
      return Array.from(prevFiles)
    })
  }

  const removeFile = (index) => {
    setFiles((prevFiles) => {
      prevFiles.splice(index, 1)
      return Array.from(prevFiles)
    })
  }

  const openUploadPrompt = (e) => {
    console.log('event', e.target.files)
    let filesToUpload = Array.from(e.target.files).map((x) => {
      return {
        file: x,
        progress: 0,
        status: ''
      }
    })

    let promises = []

    for (let i = 0; i < filesToUpload.length; i++) {
      promises.push(new Promise((resolve, reject) => {
        const file = filesToUpload[i].file
        const reader = new FileReader()
        reader.onload = (event) => {
          const binary = event.target.result;
          filesToUpload[i].md5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(binary))
          resolve()
        }
        reader.readAsBinaryString(file);
      }))
    }

    Promise.all(promises)
    .then(() => {
      setFiles(filesToUpload)
      setShowUploadContent(true)
    })
  }

  React.useEffect(() => {
    if (files.length === 0 && filesAlreadyUploaded.length === 0) {
      handleClose()
    } else {
      let newFilesAlreadyUploaded = []
      let i = 0
      let filesChanged = false
      while (i < files.length) {
        const md5 = files[i].md5
        let fileAlreadyExists = false
        if (props.photoMetadata !== null) {
          if(props.photoMetadata.some((item) => item.etag === md5.toString())) {
            console.log('Photo already exists:', md5.toString())
            newFilesAlreadyUploaded.push(files.splice(i, 1).pop())
            filesChanged = true
            fileAlreadyExists = true
          }
        }

        if (!fileAlreadyExists) {
          i++
        }
      }

      if (filesChanged) {
        console.log('files changed')
        console.log('new files:', files)
        console.log('new files already uploaded:', newFilesAlreadyUploaded)
        setFiles(Array.from(files))
        setFilesAlreadyUploaded(newFilesAlreadyUploaded)
      }
    }
  }, [filesAlreadyUploaded.length, handleClose, props, files])

  return (
    <React.Fragment>
      {props.authenticated &&
        <Fab color="primary" component='label' className={classes.fab}>
          <AddIcon />
          <input accept="image/*,video/*" className={classes.input} id="icon-button-file" type="file" multiple onChange={openUploadPrompt} />
        </Fab>
      }

      <Dialog open={showUploadContent} onClose={handleClose} fullWidth maxWidth='md'>
        {filesAlreadyUploaded.length > 0 ? (
          <form onSubmit={handleClose}>
            <DialogTitle>Found duplicate photos</DialogTitle>
            <DialogContent dividers={true}>
              <Typography variant='body1'>Some photos have already been uploaded, these will be skipped.</Typography>
              <Grid container spacing={2}>
                {filesAlreadyUploaded.map((entry, index) => {
                  console.log('already uploaded entry:', entry)
                  const imagePreview = URL.createObjectURL(entry.file)

                  return (
                    <Grid item xs={6} md={4}>
                      <img src={imagePreview} alt={entry.file.name} className={classes.previewImage} />
                    </Grid>
                  )
                })}
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={handleClose}
              >
                OK
              </Button>
            </DialogActions>
          </form>
        ) : (
          <form onSubmit={handleUploadFiles}>
            <DialogTitle>Upload new content</DialogTitle>
            <DialogContent dividers={true}>
              {files.map((entry, index) => {
                const imagePreview = URL.createObjectURL(entry.file)

                return (
                  <Grid container key={index} spacing={2} alignItems='center'>
                    <Grid item xs={12}>
                      <img src={imagePreview} alt={entry.file.name} className={classes.previewImage} />
                    </Grid>
                    <Grid item>
                      {processing ? (
                        <CircularProgress variant="determinate" value={entry.progress} />
                      ) : (
                        <DeleteIcon onClick={() => { removeFile(index) }} />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        placeholder="What's the story of this picture? (optional)"
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </DialogContent>

            <DialogActions>
              {!processing && (
                <Button onClick={handleClose}>
                  Cancel
                </Button>
              )}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={processing}
                onClick={handleUploadFiles}
              >
                Upload
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </React.Fragment>
  );
}
