/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addPhotoDescription = /* GraphQL */ `
  mutation AddPhotoDescription($description: String!, $etag: String!) {
    addPhotoDescription(description: $description, etag: $etag) {
      archived
      description
      etag
      photo_timestamp
      s3_key_prefix
      uploaded
      uploaded_by
    }
  }
`;
