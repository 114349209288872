/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const photoAdded = /* GraphQL */ `
  subscription PhotoAdded {
    photoAdded {
      archived
      description
      etag
      height
      original_filename
      photo_timestamp
      s3_key_prefix
      uploaded
      uploaded_by
      width
    }
  }
`;
export const photoRejected = /* GraphQL */ `
  subscription PhotoRejected {
    photoRejected {
      archived
      description
      etag
      height
      original_filename
      photo_timestamp
      reason
      s3_key_prefix
      uploaded
      uploaded_by
      width
    }
  }
`;
export const photoUploaded = /* GraphQL */ `
  subscription PhotoUploaded {
    photoUploaded {
      archived
      description
      etag
      height
      original_filename
      photo_timestamp
      reason
      s3_key_prefix
      uploaded
      uploaded_by
      width
    }
  }
`;
